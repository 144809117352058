<template>
    <div>
        video
    </div>
</template>
<script>
export default {
  name: "live-video",
  data () {
    return {
    };
  }
}
</script>
<style lang="less" scoped>
</style>